import React from 'react'
import { Grid, Link } from '@mui/material'
import { convertUrlToRelativePath, parseUrl } from 'utils/sidePanel'
import KCItem from './KCItem'

interface Props {
  category: string
  kcUrls: string[]
}

const ResourcesSection = ({ category, kcUrls }: Props) => {
  return (
    <>
      {kcUrls.map((link: string) => {
        const content = parseUrl(link)
        const relativeLink = convertUrlToRelativePath(link)
        return (
          <Grid item xs={6} marginTop={2} key={relativeLink}>
            <Link
              href={relativeLink}
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'unset',
                textDecoration: 'unset',
                position: 'relative',
                display: 'block'
              }}
            >
              <KCItem content={content} goalCategory={category} />
            </Link>
          </Grid>
        )
      })}
    </>
  )
}

export default ResourcesSection
