import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const NoResults = ({ clearAllFilters }: any) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='176px'
      position='relative'
      zIndex='1'
    >
      <Stack spacing={2.5}>
        <Typography variant='h5' lineHeight='65px'>
          {`We couldn't find a matching goal. Try clearing your filters.`}
        </Typography>
        <Box display='flex' justifyContent='center'>
          <Typography
            onClick={() => clearAllFilters()}
            variant='body14'
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Clear filters
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default NoResults
