import React, { useEffect, useMemo, useState } from 'react'
import usePortfolio from 'hooks/usePortfolio'
import { GridSortModel } from '@mui/x-data-grid-pro'
import useGoal from 'hooks/useGoal'

type GoalContext = {
  portfolioEmployerGoals: any
  handleSortModelChange: (sortModel: GridSortModel) => void
  selectedGoal: any
  selectGoal: (id: string) => void
  employerGoal: any
  filters: string[]
  employerGoalLoading: boolean
  portfolioEmployerGoalsLoading: boolean
}
export const PortfolioContext = React.createContext<GoalContext>({
  portfolioEmployerGoals: undefined,
  handleSortModelChange: () => {},
  selectedGoal: {},
  selectGoal: () => {},
  employerGoal: {},
  filters: [],
  employerGoalLoading: false,
  portfolioEmployerGoalsLoading: true
})

export const GoalProvider = (props: any): JSX.Element => {
  const {
    portfolioEmployerGoals,
    updateOrganizationNameSortDirection,
    portfolioEmployerGoalsLoading
  } = usePortfolio()
  const { employerGoal, loadGoal, employerGoalLoading } = useGoal()

  const [selectedGoalId, setSelectedGoalId] = useState('')

  const selectedGoal = portfolioEmployerGoals.employerGoals?.find(
    (goal: any) => `${goal.employerGoalId}` === `${selectedGoalId}`
  )

  useEffect(() => {
    if (portfolioEmployerGoals?.totalCount > 0 && selectedGoal) {
      loadGoal(selectedGoal.cakeEpId, selectedGoalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoalId, portfolioEmployerGoals])
  const values = useMemo(() => {
    const handleSortModelChange = (sortModel: GridSortModel) => {
      updateOrganizationNameSortDirection(sortModel[0]?.sort)
    }
    const selectGoal = (goalId: string) => {
      setSelectedGoalId(goalId)
    }

    return {
      portfolioEmployerGoals: portfolioEmployerGoals?.employerGoals ?? [],
      handleSortModelChange,
      selectedGoal,
      selectGoal,
      employerGoal,
      filters: portfolioEmployerGoals?.filters?.epNames ?? [],
      employerGoalLoading,
      portfolioEmployerGoalsLoading
    }
  }, [
    portfolioEmployerGoals?.employerGoals,
    portfolioEmployerGoals?.filters?.epNames,
    selectedGoal,
    employerGoal,
    employerGoalLoading,
    portfolioEmployerGoalsLoading,
    updateOrganizationNameSortDirection
  ])
  const { children } = props
  return (
    <PortfolioContext.Provider value={values}>
      {children}
    </PortfolioContext.Provider>
  )
}
