import React from 'react'
import { IconButton, Typography, Grid, Box } from '@mui/material'
import { Color } from '@mathison-inc/components'
import { KCLinkData } from 'utils/sidePanel'
import { ChevronRightIcon } from '@mathison-inc/icons'

export enum GoalCategory {
  'all' = 'All Categories',
  'defining_tracking' = 'Defining & Tracking',
  'attracting_sourcing' = 'Attracting & Sourcing',
  'interviewing_engaging' = 'Interviewing & Engaging',
  'onboarding_advancing' = 'Onboarding & Advancing'
}
export const CATEGORY_COLOR_4_TEXT: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry400,
  'Attracting & Sourcing': Color.eggplant400,
  'Interviewing & Engaging': Color.turquoise400,
  'Onboarding & Advancing': Color.iris400
}
export const CATEGORY_COLOR_1_BKGD: { [key: string]: string } = {
  'Defining & Tracking': Color.raspberry100,
  'Attracting & Sourcing': Color.eggplant100,
  'Interviewing & Engaging': Color.turquoise100,
  'Onboarding & Advancing': Color.iris100
}
interface Props {
  content: KCLinkData
  goalCategory: string
}

const KCItem = ({ content, goalCategory }: Props) => {
  const { cate, title, type } = content
  const borderColor =
    CATEGORY_COLOR_4_TEXT[
      GoalCategory[goalCategory as keyof typeof GoalCategory]
    ] || Color.border

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: '8px',
        padding: '16px'
      }}
    >
      <Grid
        container
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Grid
          item
          xs={9}
          sx={{
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: 0,
            msTransform: 'translate(0, -50%)',
            transform: 'translate(0, -50%)'
          }}
        >
          {type === 'knowledge-center' && cate && (
            <Typography variant='body14' display='block'>
              {cate}
            </Typography>
          )}
          <Typography variant='body14Light'>{title}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}
        >
          <IconButton
            sx={{
              height: 40,
              width: 40,
              backgroundColor:
                CATEGORY_COLOR_1_BKGD[
                  GoalCategory[goalCategory as keyof typeof GoalCategory]
                ]
            }}
          >
            <ChevronRightIcon size={40} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
export default KCItem
