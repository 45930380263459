import { ArrowUpward, ArrowDownward, ArrowForward } from '@mui/icons-material'
import { Grid, Typography, Stack } from '@mui/material'
import React from 'react'

interface Props {
  impactValue: string
  effortValue: string
}

const Effort = ({ effortValue, impactValue }: Props) => {
  return (
    <Grid item xs={5.5} marginTop={4}>
      {effortValue === 'high' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            High Effort
          </Typography>
          <ArrowUpward sx={{ color: '#FE6868' }} />
        </Stack>
      )}
      {effortValue === 'low' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Low Effort
          </Typography>
          <ArrowDownward sx={{ color: '#32B996' }} />
        </Stack>
      )}
      {effortValue === 'medium' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Med Effort
          </Typography>
          <ArrowForward sx={{ color: '#FCA625' }} />
        </Stack>
      )}
      {effortValue === null && impactValue !== null && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Effort not set
          </Typography>
        </Stack>
      )}
    </Grid>
  )
}

export default Effort
