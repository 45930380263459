import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Typography } from '@mui/material'
import { Color } from '@mathison-inc/components'

interface Props {
  disabled?: boolean
}
const StatusTag = styled(({ label, ...props }: Props & ChipProps) => (
  <Chip
    {...props}
    label={<Typography variant='subtitle2'>{label}</Typography>}
  />
))(({ label }: ChipProps) => {
  const baseStyle = {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
    borderRadius: '8px',
    fontSize: '12px',
    backgroundColor: Color.grey50,
    borderColor: Color.grey300,
    maxHeight: '24px',
    padding: '0 12px',
    '& .MuiChip-label': {
      padding: '8px 0px'
    }
  }

  switch (label) {
    case 'TO DO':
      return {
        ...baseStyle,
        backgroundColor: Color.tangerine100,
        padding: '0 8px'
      }
    case 'ON TRACK':
      return {
        ...baseStyle,
        backgroundColor: Color.jade100,
        padding: '0 8px'
      }
    case 'COMPLETED':
      return {
        ...baseStyle,
        backgroundColor: Color.purple100,
        padding: '0 8px'
      }
    case 'OFF TRACK':
      return {
        ...baseStyle,
        backgroundColor: Color.red100,
        padding: '0 8px'
      }

    default:
      return baseStyle
  }
})

export default StatusTag
