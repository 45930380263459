import { getEIStage, parseGoalCategoryToFull } from '@mathison-inc/components'
import { CircularProgress, Grid } from '@mui/material'
import { PortfolioContext } from 'context/PortfolioContext'
import React, { useContext } from 'react'
import SidePanelGoalDetails from './SidePanelGoalDetails'
import SidePanelHeader from './SidePanelHeader'
import SidePanelPage from './SidePanelPage'
import SidePanelSectionTitle from './SidePanelSectionTitle'
import SidePanelResourcesSection from './SidePanelResourcesSection'
import SidePanelActionsToConsider from './SidePanelActionsToConsider'

interface Props {
  isSidePanelOpen: boolean
  closeSidePanel: () => void
}

const SidePanel = ({ isSidePanelOpen, closeSidePanel }: Props) => {
  const { selectedGoal, employerGoal, employerGoalLoading } =
    useContext(PortfolioContext)

  if (!selectedGoal || !employerGoal || employerGoalLoading)
    return (
      <SidePanelPage isOpen={isSidePanelOpen} onClose={() => closeSidePanel()}>
        <div style={{ width: '994px', display: 'flex', paddingTop: '48px' }}>
          <CircularProgress size={40} thickness={4} sx={{ margin: 'auto' }} />
        </div>
      </SidePanelPage>
    )

  const {
    impact,
    effort,
    startDate,
    endDate,
    employerGoalActions,
    description,
    category,
    kcUrls
  } = employerGoal

  return (
    <SidePanelPage isOpen={isSidePanelOpen} onClose={() => closeSidePanel()}>
      <SidePanelHeader
        organizationName={selectedGoal.epName}
        EIScore={selectedGoal.eiScore}
        EIStage={getEIStage(selectedGoal.eiScore)}
        goalTagTitle={parseGoalCategoryToFull(selectedGoal.category)}
        title={selectedGoal.customTitle ?? selectedGoal.title}
        description={description}
      />
      <SidePanelSectionTitle sectionTitle='GOAL Details' />
      <SidePanelGoalDetails
        impactValue={impact}
        effortValue={effort}
        rawStartDate={startDate}
        rawEndDate={endDate}
      />
      {employerGoalActions.length > 0 && (
        <SidePanelSectionTitle sectionTitle='Actions to Consider' />
      )}

      <SidePanelActionsToConsider actionDetails={employerGoalActions} />
      {kcUrls.length > 0 && (
        <SidePanelSectionTitle sectionTitle='Mathison resources' />
      )}
      <Grid container spacing={3} sx={{ padding: '0 40px' }}>
        <SidePanelResourcesSection category={category} kcUrls={kcUrls} />
      </Grid>
      {/* <SidePanelSectionTitle sectionTitle='SUPPORTING DATA' /> */}
    </SidePanelPage>
  )
}

export default SidePanel
