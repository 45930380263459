import { Drawer } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}
const SidePanelPage = ({ isOpen, onClose, children }: Props) => {
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={() => {
        onClose()
      }}
      sx={{
        zIndex: 1300,
        '& .MuiBackdrop-root': {
          backgroundColor: 'Color.grey600',
          opacity: '0.8 !important'
        }
      }}
      PaperProps={{
        sx: {
          paddingBottom: '48px'
        }
      }}
    >
      {children}
    </Drawer>
  )
}

export default SidePanelPage
