import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Grid } from '@mui/material'
import Groups from 'pages/Home'

const RouteProvider = () => {
  return (
    <Grid
      sx={{
        maxWidth: 1460,
        width: '90%',
        margin: '40px auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid sx={{ width: '100%' }}>
        <Routes>
          <Route path='/groups' element={<Groups />} />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
