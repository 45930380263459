import React from 'react'
import { Grid } from '@mui/material'
import Impact from './Impact'
import Effort from './Effort'

interface Props {
  impactValue: string
  effortValue: string
}
const ImpactEffortSection = ({ impactValue, effortValue }: Props) => {
  return (
    <Grid
      item
      xs={9}
      marginTop={3}
      display='flex'
      justifyContent='space-between'
    >
      <Impact impactValue={impactValue} effortValue={effortValue} />
      <Effort impactValue={impactValue} effortValue={effortValue} />
    </Grid>
  )
}

export default ImpactEffortSection
