import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  customTitle: string | null
}

const TitleSection = ({ customTitle }: Props) => {
  return (
    <Box sx={{ marginTop: '40px' }}>
      <Typography
        variant='h4'
        style={{
          marginTop: 40,
          width: 687,
          fontWeight: 700,
          fontSize: '28px',
          lineHeight: '32px'
        }}
      >
        {customTitle}
      </Typography>
    </Box>
  )
}

export default TitleSection
