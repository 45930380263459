import React from 'react'
import { Box, Divider, Grid, styled, Typography } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import { GoalTag } from '@mathison-inc/components'
import TitleSection from './TitleSection'
import Description from './Description'

const StyledTypography = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
`

interface Props {
  organizationName: string
  EIScore: number
  EIStage: string
  goalTagTitle: string
  title: string
  description: string
}
const PanelHeader = ({
  organizationName,
  EIScore,
  EIStage,
  goalTagTitle,
  title,
  description
}: Props) => {
  return (
    <Grid style={{ padding: '40px' }}>
      <Box paddingBottom={4} display='flex' alignItems='center'>
        <BusinessIcon />
        <StyledTypography variant='h6'>{organizationName}</StyledTypography>
        <Box display='flex' justifyContent='flex-end' marginLeft='auto'>
          <Typography>
            EI Score:{' '}
            <Typography variant='h6' component='span'>
              {EIScore ?? <b>Not taken</b>}
            </Typography>
          </Typography>
          <Typography style={{ paddingLeft: '18px' }}>
            Development stage:{' '}
            <Typography variant='h6' component='span'>
              {EIStage ?? <b>EI score needed</b>}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box marginTop={5}>
        <GoalTag label={goalTagTitle} large />
      </Box>
      <TitleSection customTitle={title} />
      <Description description={description} />
    </Grid>
  )
}

export default PanelHeader
