import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import Markdown from 'markdown-to-jsx'

interface Action {
  title: string
  description: string
  id: string
}

interface Props {
  actionDetails: Action[]
}

const ActionsToConsider = ({ actionDetails }: Props) => {
  return (
    <Stack sx={{ padding: '0 40px' }}>
      {actionDetails.map((action: Action) => (
        <>
          <Stack
            sx={{
              padding: '24px 0',
              '& .MuiTypography-root a': {
                textDecoration: 'underline !important'
              },
              maxWidth: '770px'
            }}
          >
            <Typography variant='body16'>
              <Markdown
                options={{
                  disableParsingRawHTML: true
                }}
              >
                {action.title}
              </Markdown>
            </Typography>
            <Typography variant='body16Light'>
              <Markdown options={{ disableParsingRawHTML: true }}>
                {action.description}
              </Markdown>
            </Typography>{' '}
          </Stack>
          <Divider
            sx={{
              width: '100%',
              height: '1px',
              marginBottom: 1
            }}
          />
        </>
      ))}
    </Stack>
  )
}

export default ActionsToConsider
