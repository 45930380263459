/* eslint-disable prefer-destructuring */
import parse from 'url-parse'

const lowerWordRegex =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i

const upperWords = ['dei']

const capitalize = (str: string, prepositionsTest?: boolean) =>
  lowerWordRegex.test(str) && prepositionsTest
    ? str
    : str.charAt(0).toUpperCase() + str.slice(1)

export interface KCLinkData {
  cate: string
  title: string
  type: string
}

export const convertUrlToRelativePath = (url: string) => {
  const baseUrl = 'https://app.mathison.io'
  return url.split(baseUrl).pop()
}

// Amazing function that will transfer URL to title case  by Bowen
export const parseUrl = (url: string) => {
  const result: KCLinkData = { cate: '', title: '', type: '' }
  const parsed = parse(url, true)
  const pathname = parsed.pathname
  const dirs = pathname.split('/').slice(1)
  result.type = dirs[0]
  if (result.type === 'bias-scanner') {
    result.title = 'Mathison Bias Scanner'
  }
  if (dirs[0] === 'knowledge-center') {
    switch (dirs[1]) {
      case 'category':
        result.cate = `${dirs[3]
          .split('-')
          .map((word: string) => capitalize(word))
          .join(' ')}`
        result.title = dirs[4]
          .split('-')
          .map((word: string, index: number) =>
            upperWords.includes(word)
              ? word.toUpperCase()
              : capitalize(word, index !== 0)
          )
          .join(' ')

        break
      case 'hiring-for-diversity':
        result.cate = 'Hiring for Diversity'
        result.title = `${capitalize(dirs[2])} ${dirs[3]}`
        break
      default:
        result.cate = ''
        result.title = ''
    }
  }

  return result
}
