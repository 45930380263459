import React from 'react'
import { MicroServices } from 'model/microServices'

export const MicroServiceContext = React.createContext<MicroServices>({
  children: undefined,
  maestroClient: undefined
})

export const MicroServiceProvider = (props: MicroServices): JSX.Element => {
  const { children } = props
  return (
    <MicroServiceContext.Provider value={props}>
      {children}
    </MicroServiceContext.Provider>
  )
}
