import React, { useState, useEffect } from 'react'
import { GoalProvider } from 'context/PortfolioContext'
import { Typography, CircularProgress, Box } from '@mui/material'
import Home from './home'

const HomeWrapper = () => {
  return (
    <GoalProvider>
      <Typography variant='h1' marginBottom='24px'>
        Groups
      </Typography>

      <Home />
    </GoalProvider>
  )
}

export default HomeWrapper
