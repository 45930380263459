export const parseStatus = (category: string) => {
  const letters = category?.split('_')
  if (!letters) {
    return ''
  }
  if (letters[1]) {
    return `${letters[0].toUpperCase()} ${letters[1].toUpperCase()}`
  }
  return `${letters[0].toUpperCase()}`
}
