import React from 'react'
import FilterDropdown from './FilterDropdown'

interface Props {
  organizationFilters: string[]
  filteredOrganizations: string[]
  filteredEIStages: string[]
  setFilteredOrganizations: (filteredOrganizations: string[]) => void
  setFilteredEIStage: (filteredEIStages: string[]) => void
}
const Filters = ({
  organizationFilters,
  filteredOrganizations,
  setFilteredOrganizations,
  setFilteredEIStage,
  filteredEIStages
}: Props) => {
  return (
    <>
      <FilterDropdown
        sx={{ width: '258px' }}
        label='Organization'
        values={filteredOrganizations}
        onChange={(e: any) => {
          setFilteredOrganizations(e.target.value)
        }}
        options={organizationFilters}
        clearFilter={() => {
          setFilteredOrganizations([])
        }}
        isOrganization
      />
      <FilterDropdown
        sx={{ marginLeft: '16px', width: '258px' }}
        label='EI Stage'
        values={filteredEIStages}
        onChange={(e: any) => {
          setFilteredEIStage(e.target.value)
        }}
        options={['Discovering', 'Aware', 'Engaged', 'Leading']}
        clearFilter={() => setFilteredEIStage([])}
      />
    </>
  )
}
export default Filters
