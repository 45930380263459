import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import './index.css'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import { MicroServiceProvider } from 'context/MicroService'
import { MicroServices } from './model/microServices'
import App from './App'

interface Props {
  container?: any
}
let root: Root
const render = (props: MicroServices & Props) => {
  const { maestroClient, container } = props

  root = createRoot(
    container
      ? container.querySelector('#orbit-root')
      : document.getElementById('orbit-root')
  )
  root.render(
    <React.StrictMode>
      <MicroServiceProvider maestroClient={maestroClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </MicroServiceProvider>
    </React.StrictMode>
  )
}

export const bootstrap = async () => {}

export const mount = async (props: MicroServices) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}

export const update = async (props: any) => {
  render(props)
}

// eslint-disable-next-line no-underscore-dangle
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({
    children: undefined,
    maestroClient: undefined
  })
}
