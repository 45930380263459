import { ArrowDownward, ArrowForward, ArrowUpward } from '@mui/icons-material'
import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
  impactValue: string
  effortValue: string
}

const Impact = ({ impactValue, effortValue }: Props) => {
  return (
    <Grid item xs={5.5}>
      <Typography variant='body16Light' component='p' marginBottom='8px'>
        Prioritization
      </Typography>
      {impactValue === 'high' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            High Impact
          </Typography>
          <ArrowUpward sx={{ color: '#32B996' }} />
        </Stack>
      )}
      {impactValue === 'low' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Low Impact
          </Typography>
          <ArrowDownward sx={{ color: '#FE6868' }} />
        </Stack>
      )}
      {impactValue === 'medium' && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Med Impact
          </Typography>
          <ArrowForward sx={{ color: '#FCA625' }} />
        </Stack>
      )}
      {impactValue === null && effortValue !== null && (
        <Stack direction='row'>
          <Typography variant='body16' paddingRight={1}>
            Impact not set
          </Typography>
        </Stack>
      )}
      {impactValue === null && effortValue === null && (
        <Stack direction='row'>
          <Typography variant='body16' whiteSpace='nowrap' paddingRight={1}>
            Impact and Effort not set
          </Typography>
        </Stack>
      )}
    </Grid>
  )
}

export default Impact
