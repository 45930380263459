//  <reference types="@mui/system" />
import React from 'react'
import { Box, styled, Typography, Divider } from '@mui/material'

interface Props {
  sectionTitle?: string
  hasDivider?: boolean
}

const SidePanelSectionTitle = styled(({ sectionTitle, hasDivider }: Props) => (
  <Box sx={{ padding: '0 40px' }}>
    <Typography
      variant='subtitle2'
      sx={{
        marginTop: 5,
        textAlign: 'left',
        textTransform: 'uppercase',
        marginBottom: '8px'
      }}
    >
      {sectionTitle}
    </Typography>
    {hasDivider && <Divider />}
  </Box>
))()

SidePanelSectionTitle.defaultProps = {
  hasDivider: true
}

export default SidePanelSectionTitle
