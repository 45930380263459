/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useState } from 'react'
import { DataGridPro, GridValueGetterParams } from '@mui/x-data-grid-pro'
import {
  Paper,
  GoalTag,
  parseGoalCategory,
  Color,
  EIStage,
  getEIStage
} from '@mathison-inc/components'
import StatusTag from 'components/StatusTag'
import { parseStatus } from 'utils/statusTagHelper'
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowForward as ArrowForwardIcon
} from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'
import { PortfolioContext } from 'context/PortfolioContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BusinessIcon from '@mui/icons-material/Business'
import NoResults from './NoResults'
import { tableStyles } from './styles'
import Filters from './Filters'
import SidePanel from './SidePanel'

const sortCol = (sortMap: string[]) => (a: string, b: string) => {
  if (a === null) return 1
  if (b === null) return -1
  return sortMap.indexOf(a) - sortMap.indexOf(b)
}
const Categories = [
  'defining_tracking',
  'attracting_sourcing',
  'interviewing_engaging',
  'onboarding_advancing'
]
const ImpactEffort = ['low', 'medium', 'high']

const getArrowIcon = (value: any, column: string) => {
  switch (column) {
    case 'impact':
      switch (value) {
        case 'high':
          return (
            <ArrowUpwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.jade400}
            />
          )
        case 'low':
          return (
            <ArrowDownwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.red400}
            />
          )
        case 'medium':
          return (
            <ArrowForwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.tangerine400}
            />
          )
        default:
          return null
      }
    case 'effort':
      switch (value) {
        case 'high':
          return (
            <ArrowUpwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.red400}
            />
          )
        case 'low':
          return (
            <ArrowDownwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.jade400}
            />
          )
        case 'medium':
          return (
            <ArrowForwardIcon
              sx={{ fontSize: '16px', marginLeft: '4px' }}
              fontSize='small'
              htmlColor={Color.tangerine400}
            />
          )
        default:
          return null
      }
    default:
      return null
  }
}

const getImpactText = (value: any) => {
  switch (value) {
    case 'low':
      return <Typography>Low</Typography>
    case 'medium':
      return <Typography>Med</Typography>
    case 'high':
      return <Typography>High</Typography>
    default:
      return null
  }
}

const Status = ['to_do', 'on_track', 'off_track', 'completed', 'archived']

const Home = () => {
  const {
    portfolioEmployerGoals,
    selectGoal,
    filters,
    selectedGoal,
    portfolioEmployerGoalsLoading: isLoading
  } = useContext(PortfolioContext)
  const [filteredOrganizations, setFilteredOrganizations] = useState<string[]>(
    []
  )
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const [filteredEIStages, setFilteredEIStage] = useState<string[]>([])
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  let rows = portfolioEmployerGoals

  if (filteredOrganizations.length > 0) {
    rows = rows.filter((row: any) => filteredOrganizations.includes(row.epName))
  }
  if (filteredEIStages.length > 0) {
    rows = rows.filter((row: any) =>
      filteredEIStages.includes(getEIStage(row.eiScore))
    )
  }

  useEffect(() => {
    const existingTitle = document.title
    document.title = 'Groups | Mathison'
    const goalId = queryParams.get('id')
    if (goalId) {
      selectGoal(goalId)
      setIsSidePanelOpen(true)
    }
    return () => {
      document.title = existingTitle
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedGoal) {
      document.title = `${
        selectedGoal.customTitle ?? selectedGoal.title
      } - Groups | Mathison`
      setIsSidePanelOpen(true)
    } else {
      document.title = 'Groups | Mathison'
    }
  }, [selectedGoal])

  return (
    <div>
      <SidePanel
        isSidePanelOpen={isSidePanelOpen}
        closeSidePanel={() => {
          setIsSidePanelOpen(false)
          selectGoal('')
          navigate(`.`)
          setSelectionModel([])
        }}
      />
      <Paper
        sx={{
          width: '100%',
          padding: '33px'
        }}
      >
        <Filters
          organizationFilters={filters}
          filteredOrganizations={filteredOrganizations}
          filteredEIStages={filteredEIStages}
          setFilteredOrganizations={setFilteredOrganizations}
          setFilteredEIStage={setFilteredEIStage}
        />
        <Divider sx={{ margin: '24px 0' }} />

        <DataGridPro
          autoHeight
          loading={isLoading}
          onRowSelectionModelChange={(selectedRow: any) => {
            setSelectionModel(selectedRow)
          }}
          rowSelectionModel={selectionModel}
          pagination
          disableColumnMenu
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            NoRowsOverlay: () => (
              <NoResults
                clearAllFilters={() => {
                  setFilteredEIStage([])
                  setFilteredOrganizations([])
                }}
              />
            )
          }}
          rowHeight={100}
          pageSizeOptions={[5, 10, 25]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 }
            }
          }}
          onRowClick={(rowSet: any) => {
            const { id } = rowSet
            selectGoal(id)
            navigate(`?id=${id}`)
          }}
          getRowId={(row: any) => row.employerGoalId}
          sx={tableStyles}
          rows={rows}
          columns={[
            {
              headerName: 'Organization',
              field: 'name',
              width: 250,
              headerClassName: 'headers',
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.epName
              },
              renderCell: params => {
                return (
                  <>
                    {/* TODO: remove this line after logo service is fixed */}
                    {params.row.avatarUrl && false ? (
                      <img
                        src={params.row.avatarUrl}
                        width='32'
                        height='32'
                        alt='avatar'
                      />
                    ) : (
                      <BusinessIcon sx={{ color: '#747779' }} />
                    )}
                    <Typography variant='body2' marginLeft='8px'>
                      {params.value}
                    </Typography>
                  </>
                )
              }
            },
            {
              headerName: 'EI Stage',
              field: 'EI Stage',
              width: 125,
              headerClassName: 'headers',
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.eiScore
              },
              renderCell: params => {
                return <EIStage acquiredScore={params?.row?.eiScore} />
              }
            },
            {
              headerName: 'EI Score',
              field: 'EIScore',
              width: 110,
              headerClassName: 'headers',
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.eiScore
              }
            },

            {
              headerName: 'Goal',
              field: 'goal',
              headerClassName: 'headers',
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.customTitle ?? params?.row?.title
              },
              width: 330,

              renderCell: params => {
                return (
                  <Typography
                    variant='body2'
                    style={{
                      display: '-webkit-box',
                      wordBreak: 'break-word',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '2',
                      whiteSpace: 'normal',
                      overflow: 'hidden'
                    }}
                  >
                    {params.value}
                  </Typography>
                )
              }
            },
            {
              headerName: 'Category',
              field: 'category',
              width: 120,
              headerClassName: 'headers',
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.category
              },
              renderCell: params => {
                return <GoalTag label={parseGoalCategory(params.value)} />
              },
              sortComparator: sortCol(Categories)
            },
            {
              headerName: 'Status',
              field: 'satus',
              width: 135,
              headerClassName: 'headers',
              renderCell: params => {
                if (!params.value) return ''
                return <StatusTag label={parseStatus(params.value)} />
              },
              valueGetter: (params: GridValueGetterParams) => {
                return params?.row?.status
              },
              sortComparator: sortCol(Status)
            },
            {
              headerName: 'Impact',
              field: 'impact',
              headerClassName: 'headers',
              width: 115,
              valueGetter: params => params.row.impact,
              renderCell: params => {
                if (!params.value) {
                  return ''
                }
                return (
                  <>
                    <Typography sx={{ width: 36 }} variant='body1'>
                      {getImpactText(params.value)}
                    </Typography>
                    {getArrowIcon(params.value, 'impact')}
                  </>
                )
              },
              sortComparator: sortCol(ImpactEffort)
            },
            {
              headerName: 'Effort',
              field: 'effort',
              width: 105,
              headerClassName: 'headers',
              valueGetter: params => params.row.effort,
              renderCell: params => {
                if (!params.value) {
                  return ''
                }
                return (
                  <>
                    <Typography sx={{ width: 36 }} variant='body1'>
                      {getImpactText(params.value)}
                    </Typography>
                    {getArrowIcon(params.value, 'effort')}
                  </>
                )
              },
              sortComparator: sortCol(ImpactEffort)
            }
          ]}
        />
      </Paper>
    </div>
  )
}

export default Home
