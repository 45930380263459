import { gql, useQuery } from '@apollo/client'
import { GridSortDirection } from '@mui/x-data-grid-pro'

const PORTFOLIO_EMPLOYER_GOALS = gql`
  query PortfolioEmployerGoals {
    portfolioEmployerGoals {
      filters {
        epNames
      }
      totalCount
      employerGoals {
        epId
        cakeEpId
        avatarUrl
        category
        effort
        eiScore
        title
        customTitle
        impact
        employerGoalId
        epName
        status
      }
    }
  }
`
const usePortfolio = () => {
  const {
    data = { portfolioEmployerGoals: {} },
    refetch,
    loading
  } = useQuery(PORTFOLIO_EMPLOYER_GOALS)
  const updateOrganizationNameSortDirection = (
    direction: GridSortDirection
  ) => {
    refetch({ direction })
  }
  const { portfolioEmployerGoals } = data

  return {
    portfolioEmployerGoals,
    updateOrganizationNameSortDirection,
    portfolioEmployerGoalsLoading: loading
  }
}
export default usePortfolio
