import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import moment from 'moment'

interface Props {
  rawStartDate: string
  rawEndDate: string
}
const Timeline = ({ rawStartDate, rawEndDate }: Props) => {
  return (
    <Grid item xs={6} marginTop={3}>
      <Typography variant='body16Light' component='p' marginBottom='8px'>
        Timeline
      </Typography>
      {(rawStartDate || rawEndDate) && (
        <Stack direction='row' spacing={2} alignItems='baseline'>
          <Typography variant='body16'>
            {rawStartDate
              ? moment(rawStartDate).format('MMM YYYY')
              : 'No start date'}
          </Typography>
          <Box>
            <EastIcon sx={{ verticalAlign: '-6px' }} />
          </Box>
          <Typography variant='body16'>
            {rawEndDate ? moment(rawEndDate).format('MMM YYYY') : 'No end date'}
          </Typography>
        </Stack>
      )}
      {rawStartDate === null && rawEndDate === null && (
        <Stack direction='row' spacing={2} alignItems='baseline'>
          <Typography variant='body16'>No start and end dates set</Typography>
        </Stack>
      )}
    </Grid>
  )
}

export default Timeline
