import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  description: string
}

const Description = ({ description }: Props) => {
  return (
    <Box paddingTop='24px' maxWidth='534px'>
      <Typography variant='body16Light'>{description}</Typography>
    </Box>
  )
}

export default Description
