import React from 'react'
import { Grid } from '@mui/material'
import Prioritization from './Prioritization'
import Timeline from './Timeline'

interface Props {
  impactValue: string
  effortValue: string
  rawStartDate: string
  rawEndDate: string
}
const SidePanelGoalDetails = ({
  impactValue,
  effortValue,
  rawStartDate,
  rawEndDate
}: Props) => {
  return (
    <Grid container spacing={3} sx={{ paddingLeft: '40px' }}>
      <Grid item xs={6} display='flex'>
        <Prioritization impactValue={impactValue} effortValue={effortValue} />
      </Grid>
      <Grid item xs={6} display='flex'>
        <Timeline rawStartDate={rawStartDate} rawEndDate={rawEndDate} />
      </Grid>
    </Grid>
  )
}

export default SidePanelGoalDetails
