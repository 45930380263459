import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'

const EMPLOYER_GOAL = gql`
  query EmployerGoal($employerId: String!, $employerGoalId: String!) {
    employerGoal(employerGoalId: $employerGoalId, employerId: $employerId) {
      id
      employerGoalActions {
        id
        title
        description
      }
      effort
      impact
      startDate
      description
      endDate
      category
      kcUrls
    }
  }
`
const useGoal = () => {
  const { refetch } = useQuery(EMPLOYER_GOAL, {
    variables: {
      employerId: '',
      employerGoalId: ''
    },
    skip: true
  })
  const [employerGoal, setEmployerGoal] = useState()
  const [employerGoalLoading, setEmployerGoalLoading] = useState(true)
  const loadGoal = async (employerId: string, employerGoalId: string) => {
    setEmployerGoalLoading(true)
    const { data } = await refetch({
      employerId,
      employerGoalId: `${employerGoalId}`
    })
    setEmployerGoal(data.employerGoal)
    setEmployerGoalLoading(false)
  }

  return { employerGoal, loadGoal, employerGoalLoading }
}

export default useGoal
